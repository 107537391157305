import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'Globalscribes_digitable.png',
        name: 'Cynthia English',
        designation: 'Founder, CEO  Global Scribes',
        location: 'NC, USA', 
        description: '“Digitable is working on building a Social Network for kids with video and chat options for Global Scribes a US not for profit organization.',
       
    },

    {
        image: 'BnN_digitable.png',
        name: 'Srimal Wickramasinghe',
        designation: 'Founder, CEO Brushes & Needles',
        location: 'Poland', 
        description: '“Digitable developed the new website for Brushes and Needles which was unique and attractive. We are a leading fabric sourcing company in Sri Lanka.',
    },
    
    {
        image: 'mendis_digitable.png',
        name: 'Chandima Bambarandage',
        designation: 'Head of HR (former) Mendis',
        location: 'Sri Lanka', 
        description: '“Implemented a company wide fully automated HR system using real-time data from employees to create a paperless office with 100% data accuracy.',
      
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/digitable/${data.image}`} alt="Digitable Clients" />
                                </figure>
                                <figcaption className="content feedbackBox">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
