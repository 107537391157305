import React from 'react';
import HeaderOne from "../digitableCommon/HeaderOne";
import HeaderTopBar from '../digitableCommon/HeaderTopBar';
import FooterTwo from "../digitableCommon/FooterTwo";
import Copyright from "../digitableCommon/Copyright"

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
