import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Do you serve only to a specific business category?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>We serve organizations of any size/category from any industry.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                What are the industries Digitable specialized in?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>We are experienced in serving clients from a wide variety of industries including leisure, hospitality, transportation, agriculture, energy, finance, and manufacturing.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                What are the services you offer?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Our services include:
                        <ul> 
                            <li>System migration</li>
                            <li>Cloud solutions</li>
                            <li>ERPs</li>
                            <li>HRM solutions</li>
                            <li>CRM solutions</li>
                            <li>App and website development and maintenance</li>
                            <li>Ecommerce solutions maintenance</li>
                            <li>Digital Marketing</li>
                            <li>Business consultancy</li>
                            <li>Prototyping and MVP development</li>
                            <li>Digital asset management</li>
                            </ul>
                     </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What are the geographic areas you serve?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The digital world is not limited by geographic boundaries and so aren’t we. We serve clients from any part of the world.</Card.Body>
                </Accordion.Collapse>
            </Card>
            
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Is digital transformation expensive?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>With our expertise, we have developed innovative approaches to minimize costs associated with digital transformation.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Do you provide only technology solutions??
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>While our main focus is on digital solutions, we also offer certain traditional services (such as marketing services) to serve our clients’ requirements.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default AccordionOne

