import React from 'react';
import Typed from 'react-typed';
// import whydigital from '../../assets/images/logo/whydigital_transformation.png';
import whydigital from '../digitableAssets/images/logo/whydigital_transformation.png';
const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src={whydigital} alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Why choose<br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Digital Transformation.",
                                            "Digitable.",

                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Digital transformation is transforming or creating new business processes, customer experiences, and company cultures through digital technologies to address new requirements. This enables companies, including small and medium businesses, to streamline operations, reduce costs, and maximise productivity, while gaining and maintaining competitiveness.</p>
                                <p>By understanding the dynamic nature of the world, Digitable Pvt Ltd has established itself to be beyond a traditional digital agency. Serving organisations of all kinds and sizes, our goal is to be our clients’ partner in digital transformation. We believe digital transformation is a key for organisations in addressing the ever-changing demands.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/portfolio"><span>Check Our Services</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
