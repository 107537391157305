import React, { useState, useEffect } from "react";
import SEO from "../../digitableCommon/SEO";
import HeaderTopBar from "../../digitableCommon/HeaderTopBar";
import HeaderOne from "../../digitableCommon/HeaderOne";
import PortfolioData from "../../dataDigitable/PortfolioData.json";
import FooterTwo from "../../digitableCommon/FooterTwo";
import Copyright from "../../digitableCommon/Copyright";
import { useParams } from "react-router-dom";

const Portfoliodetails = () => {
  const params = useParams();
  // const [title, setTitle] = useState("");
  const [pageContent, setPageContent] = useState([]);
  const [pageParagraph, ] = useState([]);// setPagePragraph
  // const [bannerImage, setBannerImage] = useState("");
  useEffect(() => {
    var findDetails = PortfolioData.filter((item) => item.id === params.id)[0];
    setPageContent(findDetails); 
    findDetails.body.map((content, index) => {
      pageParagraph.push(
        <div key={index}>
          <p className="mb--10">{content}</p>
        </div>
      );
      return null;
    });

    return ()=>{
      console.log('clear');
    }
  }, [pageParagraph,params.id]);
   
  return (
    <>
      <SEO title="Digitable | Prototyping & MVP Development" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* Start Slider Area  */}{" "}
        <div
          className="slider-area slider-style-1 height-650 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + pageContent.image
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <h5 className="title display-one">{pageContent.title}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}{" "}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title ml--90">{pageContent.title}</h3>
                  </div>
                </div> */}
              <div className="col-lg-12 mb--50">{pageParagraph}</div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default Portfoliodetails;
