import React from "react";
import SEO from "../../digitableCommon/SEO";
import Layout from "../../digitableCommon/Layout";
import BreadcrumbOne from "../../elementsDigitable/BreadcrumbOne";
import SectionTitle from "../../elementsDigitable/SectionTitle";
import PortfolioOne from "./PortfolioOne";

const Portfolio = () => {
  return (
    <>
      <SEO
        title="Digitable | Portfolio"
        description="The solutions and services of Digitable ranges from a basic process automation to a 360 degree transformation of the business"
        keyword="Consultancy, BPO, E-Commerce Management, Digital Asset Management, Website Development, Mobile App development, HR Solutions, Logistics Solutions"
      />
      <Layout>
        <BreadcrumbOne
          title="Adopt The Latest Technology <br /> Stay Ahead In The Game."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Digitable Portfolio"
        />
        <div className="main-content">
          <div className="rwt-portfolio-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Solution Portfolio"
                    title="We Provide Customized Solutions"
                    description=""
                  />
                </div>
              </div>
              <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Portfolio;
