import PropTypes from "prop-types";
import React from 'react'

const SEO = ( {title, description, keyword} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="facebook-domain-verification" content="nleb4ky4a4ybgu78jz2szb62ndnryv" />
            <meta name="description" content={description} />
            <meta name="keyword" content={keyword}/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keyword: PropTypes.string
};

export default SEO;


//Doob – Business & Finance React JS Template line 12