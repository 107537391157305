import React from "react";
import SEO from "../../digitableCommon/SEO";
import Layout from "../../digitableCommon/Layout";
import BreadcrumbOne from "../../elementsDigitable/BreadcrumbOne";
import SectionTitle from "../../elementsDigitable/SectionTitle";
import ContactOne from "./ContactOne";

const Contact = () => {
  return (
    <>
      <SEO
        title="Digitable | Contact"
        description="Contact us at Digitable for a quote on our services or if you are looking for more information about our products."
        keyword="Contact Digitable, Get in Touch, Get a Quote, Send a message"
      />
      <Layout>
        <BreadcrumbOne
          title="Our solutions are scaled to <br/> suit your business."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contact Details"
                    title="Reach Out for More Details"
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
