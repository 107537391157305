import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../elementsDigitable/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Future ready <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Strategies.",
                                        "Processes.",
                                        "Solutions.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Digital transformation requires reimagining the world in the digital age and we are here to help. We offer 360-degree digital transformation services. </p>
                            <p>Being a true partner to our clients, we make sure to understand their specific needs in order to offer customized solutions. Thanks to our approach of customization and minimalistic overheads, we can offer our services to organizations with smaller budgets, enabling them to grow further.</p>
                            <dl>
                                <dt>We also offer you</dt>
                                <li>Overll marketing services</li>
                                <li>Business consultancy</li>
                                <li>Technology direction</li>
                                <li>Prototyping services and MVP development</li>
                            </dl>

                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/contact">Inquire Now<i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
