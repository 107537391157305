import React from "react";
import SEO from "../../digitableCommon/SEO";
import HeaderTopBar from "../../digitableCommon/HeaderTopBar";
import HeaderOne from "../../digitableCommon/HeaderOne";
import BrandThree from "../../elementsDigitable/BrandThree";
import AboutFour from "../../elementsDigitable/AboutFour";
import ServiceOne from "../../elementsDigitable/ServiceOne";
import SectionTitle from "../../elementsDigitable/SectionTitle";
import Separator from "../../elementsDigitable/Separator";
import ProgressbarOne from "../../elementsDigitable/ProgressbarOne";
import FooterTwo from "../../digitableCommon/FooterTwo";
import Copyright from "../../digitableCommon/Copyright";

const DigitableAboutUs = () => {
  return (
    <>
      <SEO
        title="Digitable | About"
        description="Digitable Private Limited is a facilitator for local & International businesses to go global with its unique and customized solutions. "
        keyword="About Digitable, Digital Transformation, Digital Service Provider, Customized Solutions, Tailored Solutions"
      />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/digitable/bg/about_digitable.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">More About Us</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    It’s Time To Transform <br /> Your Business Digitally
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    The world is dynamic and increasingly being digitised, start
                    today to be ahead!e
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  The world is dynamic and increasingly being digitalized.
                  Unless you adapt, you will get left behind. Our goal is to
                  drive digital transformation for businesses and other
                  organizations by clearly understanding their needs and
                  offering simplified, yet highly effective IT-backed robust
                  business solutions. With our decades of experience with IT
                  solutions in a wide range of industries, we provide customized
                  digital transformation solutions to organizations of all kinds
                  and sizes, being a true partner in digital transformation.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What Services we offer"
                  title="End-to-end solutions"
                  description="Stay agile with our technology lead solutions and together lets outrun the speed of Digital Disruption"
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
              textAlign="text-left"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What We Are Good At"
                  title="We Specialize in a Diverse Range"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <ProgressbarOne classVar="" />
              </div>
            </div>
          </div>
        </div>
        <Separator />

        <AboutFour image="./images/about/contact-image.jpg" />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default DigitableAboutUs;
