import React from 'react';
import { FiFacebook, FiTwitter,  FiLinkedin,  FiMapPin, FiPhone } from "react-icons/fi";// FiInstagram,FiChevronRight,
// import {Link} from "react-router-dom";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>Colombo, Sri Lanka</span></p>
                                <p><FiPhone /><span><a href="tel:+94722223547">+94 722 223 547</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li>
                                        <a href='https://www.facebook.com/digitableio' target="blank"><FiFacebook /></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/digitable_io" target="blank"><FiTwitter /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/digitable-io/" target="blank"><FiLinkedin /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
