import React, {useState, useEffect} from 'react';
import {FiChevronUp} from "react-icons/fi";
// import Blury from '../Blury';
import Blury from './Blury';


const ScrollTop = () => {
    const [visible, setVisible] = useState(false);
  
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisible);
  
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', toggleVisible);
      };
    }, []); // Empty dependency array ensures that the effect runs only once during component mount
  
    return (
      <>
        <div
          className="rn-back-top"
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
        >
          <FiChevronUp />
        </div>
        {/* Assuming Blury is a component or an element */}
        <Blury />
      </>
    );
  };


// const ScrollTop = () => {
//     const [visible, setVisible] = useState(false)
//     const toggleVisible = () => {
//         const scrolled = document.documentElement.scrollTop;
//         if (scrolled > 300){
//           setVisible(true)
//         } 
//         else if (scrolled <= 300){
//           setVisible(false)
//         }
//     };
//     const scrollToTop = () =>{
//         window.scrollTo({
//             top: 0, 
//             behavior: 'smooth'
//         });
//     };
//     window.addEventListener('scroll', toggleVisible);

//     return (
//         <>
//             <div 
//             className="rn-back-top" 
//             onClick={scrollToTop}
//             style={{display: visible ? 'inline' : 'none'}}
//             ><FiChevronUp /></div>
//             <Blury />
//         </>
//     )
// }

export default ScrollTop;