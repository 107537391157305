import React from "react";
import Typed from "react-typed";
import SEO from "../../digitableCommon/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from "../../digitableCommon/HeaderOne";
import HeaderTopBar from "../../digitableCommon/HeaderTopBar";
import FooterTwo from "../../digitableCommon/FooterTwo";
import Copyright from "../../digitableCommon/Copyright";
import AboutOne from "../../elementsDigitable/AboutOne";
import ServiceOne from "../../elementsDigitable/ServiceOne";
import TestimonialOne from "../../elementsDigitable/TestimonialOne";
import SectionTitle from "../../elementsDigitable/SectionTitle";
import Separator from "../../elementsDigitable/Separator";
import Mission from "../../elementsDigitable/Mission";
// import BlogClassicData from "../../dataDigitable/BlogList.json";
// var BlogListData = BlogClassicData.slice(0, 3);

const HomeComponent = () => {
  return (
    <>
      <SEO
        title="Digital Transformation Services | Digitable"
        description="Digitable Private Limited is a digital transformation facilitator that helps businsesse wtih Web & Mobile Development, Process Automation, Digital & Social Media Marketing, HR Solutions, Flight Management, Work Management software"
        keyword="Digital Tranformation, Automation, Techological Solutions, Digital Marketing, Mobile Application, Web Development"
      />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">
                    DIGITAL TRANSFORMATION SERVICES
                  </span>
                  <h1 className="title theme-gradient display-two">
                    We specialise in
                    <br />{" "}
                    <Typed
                      strings={[
                        "Digital Marketing.",
                        "Software Development.",
                        "Corporate Solutions.",
                        "Digital Transformation.",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Our experts guide you to reach high in your business with
                    technology lead solutions
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium btn-border round btn-icon"
                      href="/about-us"
                    >
                      About Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <a
                      className="btn-default btn-medium round btn-icon"
                      href="/contact"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What Services we offer"
                  title="End-to-end solutions"
                  description="Stay agile with our technology lead solutions and together lets outrun the speed of Digital Disruption"
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
              textAlign="text-left"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--10">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="What our Clients Say."
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-12 mt--30"
              teamStyle="card-style-default testimonial-style-one"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default HomeComponent;
