import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import Home from "./pages/digitable/home";
import DigitableAboutUs from "./pages/digitable/AboutUs";
import Portfolio from "./pages/digitable/Portfolio";
import Contact from "./pages/digitable/Contact";
import PortfolioDetails from "./pages/digitable/portfoliodetails";
import Error from "./pages/digitable/Error";

// Import Css Here
import "./assets/scss/style.scss";
import "./assets/css/customsytle.css";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            component={DigitableAboutUs}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio"}`}
            exact
            component={Portfolio}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`}
            exact
            component={PortfolioDetails}
          />
          <Route component={Error} /> {/* 404 */}
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
