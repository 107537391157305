import React from 'react';
import { FiCheck } from "react-icons/fi";

// const PopupData = [
//     {
//         id: "01",
//         image: "./images/bg/bg-image-4.jpg",
//         popupLink: [
//             'https://www.youtube.com/watch?v=xshEZzpS4CQ',
//         ],
//     }
// ]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {/* {PopupData.map((item) => (
                            <>
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                            </>
                        ))} */}
                        {/* {`${process.env.PUBLIC_URL}/images/digitable/solutions_digitable.png)`} */}
                         <div className="video-btn">
                         <img className="solutions" src={`${process.env.PUBLIC_URL}/images/digitable/solutions_digitable.png`} alt="Digitable-Solutions" />
                            </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Solutions For<br/>any<strong> Type of Business</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">For organizations of all sizes</h4>
                                            <p className="text">Our wide range of digital solutions that enable organizations of all sizes to effectively venture into digital transformation.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Enhance Productivity Through New Solutions</h4>
                                            <p className="text">We assist you to streamline processes, minimise costs, increase agility, and strengthen competitive advantage.</p>
                                        </div>
                                    </li>
                                    <li>
                                    <div className="icon">
                                    <FiCheck />
                                        </div>
                                     <div className="title-wrapper">
                                            <h4 className="title">Maintaining Continuous Partnership</h4>
                                            <p className="text">We will be with you guiding every step of transformation being a true partner in digital transformation.</p>
                                       </div>
                                    </li>
                                    <li>
                                    <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Enhance Productivity Through New Solutions</h4>
                                            <p className="text">We assist you to streamline processes, minimise costs, increase agility, and strengthen competitive advantage.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
